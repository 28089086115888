<template>
    <div>
        <div>
             <el-card class="box-card" style="width:30%;margin:20px 20px">
                <audio controls="controls" style="width:100%"   ref="audioRef">
                    <source :src="audioPathSrc" type="audio/ogg">
                </audio>
                <div style="float:right">
                    <img src="../../../assets/images/delete.png" alt="" srcset="" style="margin:10px 10px;cursor:pointer;">
                    <img src="../../../assets/images/fangda.png" alt="" srcset="" style="margin:10px 10px;cursor:pointer;">
                </div>
             </el-card>
        </div>

    <div style="width:30%;">
        <el-button @click="AddExtractApplyfn"
                class="btns"
                type="primary"
                size="small"
                style="width:100px;float:right">保存</el-button>
        <el-upload
        style="float:left;margin-left:20px"
                class="picture-card"
                :action="baseUrl+`/Intelligence_Building_API/WeatherForecast/SavePictureList?type=14`"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :on-success="successfn"
                accept=".mp3"
                multiple
                :limit="1"
                :file-list="fileList">
                <el-button size="small" type="primary">上传音频</el-button>
                </el-upload>
        
    </div>
            
    </div>
</template>


<script>
import { reactive, toRefs, onMounted,ref  } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import addyard from '@/components/addyard.vue'
import previewImg from '@/components/previewimg.vue'
import {Get_alarmAudio,InsertOrUpdate_alarmAudio} from '@/js/safeDisclose'
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {DelPictureList} from "@/js/indexApi.js";
export default{
    setup() {
        const audioRef = ref()
         const state = reactive({
            inputs1:'',
            inputs2:'',
            inputs3:'',
            inputs4:'',
            id:'',
            baseUrl: baseUrl,
            fileList:[],
            audioPath:'',
            audioPathSrc:'',
         })
          const cdata = getStorage("bscdata");
          const methods = {
                handleRemove: (file, fileList) => {// 删除
                let datas = [...file.response];
                state.filelist = fileList;
                state.imglist = [];
                console.log(file,fileList);
                state.audioPath = '';
                // DeleteFile(datas);
                },

                successfn: (response, file, fileList) => {// 上传成功
                    state.audioPath = response.toString();
                },
                // 图片
                beforeUpload: (file) => {},

                // 获取音频
            Get_alarmAudio(){
                let params = {
                    projectId:cdata.pid,
                }
                Get_alarmAudio(params).then(res=>{
                     if (res.data.Code == 1) {
                        if (res.data.Data) {
                            state.audioPathSrc =baseUrl + res.data.Data.filePath;
                            state.id = res.data.Data.ID;
                            audioRef.value.src = state.audioPathSrc;
                            state.fileList = [];
                        }
                        
                    } else {
                        ElMessage({
                        showClose: true,
                        message: res.data.message,
                        type: "error",
                        });
                    }
                })
            },
            // 保存
            AddExtractApplyfn(){
              if (state.audioPath == '') {
                return ElMessage({
                    showClose: true,
                    message: '请上传最新音频文件',
                    type: "error",
                    });
              }
                let params = {}
                if (state.id) {
                      params = {
                    filePath:state.audioPath,
                    insertUser: cdata.uid,
                    projectID: cdata.pid,
                    id:state.id
                }
                }else{
                     params = {
                    filePath:state.audioPath,
                    insertUser: cdata.uid,
                    projectID: cdata.pid,
                }
                }
                
                InsertOrUpdate_alarmAudio(params).then(res=>{
                    if (res.data.Code == 1) {
                    ElMessage({
                    showClose: true,
                    message: res.data.Message,
                    type: "success",
                    });
                    methods.Get_alarmAudio();
                } else {
                    ElMessage({
                    showClose: true,
                    message: res.data.Message,
                    type: "error",
                    });
                }
                })
            },
          }
           onMounted(() => {
            methods.Get_alarmAudio();
        });

         return {
      ...methods,
      ...toRefs(state),
      audioRef
    };
    },
}
</script>

<style lang="scss" scoped>
  .addProjects-content {
    width: 100%;
    padding: 0 20px 60px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addProjects-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:deep(.img-list) {
        width: 81px;
        height: 63px;
        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 81px;
            height: 63px;
            border-radius: 0;
          }
          .el-upload-list__item-actions:hover
            span.el-upload-list__item-preview {
            display: none;
          }
        }
        .el-upload--picture-card {
          width: 81px;
          height: 63px;
          position: relative;
          background: #f3f3f3;
          border: 1px dashed #999999;
          border-radius: 0;
          .up-icon {
            width: 34px;
            height: 35px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          i {
            position: absolute;
            right: 12px;
            bottom: 8px;
            font-size: 13px;
            color: #999999;
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }

        .hiddenBtn {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      .list-title {
        position: absolute;
        left: 186px;
        bottom: 2px;
      }

      span {
        display: inline-block;
        width: 110px;
        padding-right: 11px;
        box-sizing: border-box;
        text-align: right;
      }
      div {
        width: 337px;
      }
    }
    .addProjects-input02 {
      width: 100%;
      div {
        width: 500px;
      }

      #div1 .w-e-toolbar {
        z-index: 100 !important;
      }
      #div1 .w-e-text-container {
        z-index: 99 !important;
        height: 150px !important;
      }
    }
    &:deep(#div1) {
        .w-e-text{
           p{
          b{
            font-weight: bold !important;
            }
          i{
            font-style:italic !important;
            }
      }
        }
       
    }



    .addProjects-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }
   .def {
      background: rgb(239, 239, 239);
      color: #adbaae;
      cursor: default;
    }
</style>